<template>
  <v-dialog v-model="dialogEdit" max-width="800px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item class="apListItem" v-on="on">
        <v-list-item-icon>
          <v-icon
            class="mr-2"
            small
            style="margin-top: 0px !important"
            dark
            icon
            size="18"
            v-bind="attrs"
            v-on="on"
          >
            $edit
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <span> {{ $t("edit", { name: $tc("") }) }}</span>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title class="text-h5">{{
        $t("edit", { name: subservice.description })
      }}</v-card-title>
      <div class="close">
        <v-btn icon @click="dialogEdit = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="py-0">
            <label>{{ $t("services.description") }}</label>
            <v-text-field
              v-model="subservice.description"
              outlined
              dense
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
              size="15"
              style="padding-top: 0 !important"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-0">
            <label>{{ $t("services.time") }}</label>
            <v-text-field
              v-model="subservice.time"
              outlined
              dense
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
              size="15"
              style="padding-top: 0 !important"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="py-0">
            <label>{{ $t("services.sesions") }}</label>
            <v-text-field
              v-model="subservice.sesions"
              outlined
              dense
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
              size="15"
              style="padding-top: 0 !important"
            ></v-text-field>
          </v-col>
          <template v-if="service.id == 1 || service.id == 4">
            <v-col cols="12" sm="6" class="py-0">
              <label>{{ $t("services.body_part") }}</label>
              <v-select
                v-model="subservice.body_part"
                hide-details
                outlined
                dense
                :items="body_parts"
                @change="subservice.sub_body_part = 'all'"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <label>{{ $t("services.sub_body_part") }}</label>
              <v-select
                v-model="subservice.sub_body_part"
                hide-details
                outlined
                dense
                :items="sub_body_parts"
              ></v-select>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" class="py-0">
              <label>{{ $t("services.body_part") }}</label>
              <v-autocomplete
                v-model="subservice.sub_body_part"
                hide-details
                outlined
                dense
                :items="sub_body_parts"
                @change="changeBodyPart"
              ></v-autocomplete>
            </v-col>
          </template>
          <v-col cols="12" sm="6" class="py-0">
            <label>{{ $t("services.price") }}</label>
            <v-text-field
              v-model="subservice.price"
              outlined
              dense
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
              size="15"
              style="padding-top: 0 !important"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <label>{{ $t("services.paymentSignalPrice") }}</label>
            <v-text-field
              v-model="subservice.paymentSignalPrice"
              outlined
              dense
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
              size="15"
              style="padding-top: 0 !important"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <label>{{ $t("services.marketplace") }}</label>
            <v-select
              :items="options"
              v-model="subservice.marketplace"
              outlined
              dense
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
              size="15"
              style="padding-top: 0 !important"
              data-vv-name="options"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <label>{{ $t("services.image") }}</label>
            <v-file-input
              prepend-icon
              hide-details
              outlined
              dense
              v-model="subservice.file"
            ></v-file-input>
          </v-col>
          <v-col cols="12" class="py-0" v-if="subservice.marketplace">
            <label>{{ $t("services.marketplace_pay") }}</label>
            <v-select
              :items="options_pay"
              v-model="subservice.marketplace_pay"
              outlined
              dense
              data-vv-validate-on="blur"
              v-validate="'required'"
              hide-details
              size="15"
              style="padding-top: 0 !important"
              data-vv-name="options"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="close"
          style="height: 25px"
          elevation="0"
          class="mr-3"
          >{{ $t("cancel") }}</v-btn
        >
        <v-btn
          style="color: #363533; height: 25px; padding-top: 10px !important"
          elevation="0"
          type="submit"
        >
          <v-icon style="margin-right: 5px" size="14px" @click="save"
            >$save</v-icon
          >
          {{ $t("save", { name: "" }) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EditSubService",
  props: ["subservice"],
  data() {
    return {
      options: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
      dialogEdit: false,
      subservices: [],
      editedIndex: -1,
      // editedSubservice: {
      //   description: "",
      //   time: null,
      //   sesions: null,
      //   price: null,
      //   obligatoryPayment: null,
      //   paymentSignal: 0,
      //   paymentSignalPrice: null,
      //   marketplace: null,
      // },
      defaultItem: {
        description: "",
        time: null,
        sesions: null,
        price: null,
        obligatoryPayment: null,
        paymentSignal: 0,
        paymentSignalPrice: null,
        marketplace: null,
        parent_id: 0,
      },
    };
  },

  methods: {
    ...mapActions("subservices", ["updateSubService"]),

    close() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        // this.subservice = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.subservices[this.editedIndex], this.subservice);
      } else {
        console.log(this.subservice);
        this.updateSubService({
          service_id: this.subservice.parent_id,
          id: this.subservice.id,
          subservice: this.subservice,
        }).then((response) => {
          if (response.success) {
            console.log(response);
            this.dialogEdit = false;
            this.$emit("update");
            this.$alert(this.$t("subservice_updated"));
          } else {
            console.log("ERROR");
          }
        });
        //this.services.push(this.editedSubservice);
      }
      this.close();
    },
  },
};
</script>

<style></style>
